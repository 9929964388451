<template>
    <v-container fluid>
        <h3>Form Builder</h3>
        <v-btn
                text
                @click.prevent="showDialog"
                outlined
                class="btn btn-lg btn-add-main"
                style="background:transparent;"
        >
            <i class="fas fa-plus-circle"></i> Add New Form
        </v-btn>
        <v-btn
                text
                :to="{ name: 'manage-websites', params: { domainname: siteUrl }}"
                outlined
                class="btn btn-lg btn-secondary-main"
                style="background:transparent;"
        >
            <i class="fas fa-arrow-left"></i> Back
        </v-btn>
        <span class="btn btn-label-primary btn-sm btn-bold kt-margin-r-10 pull-right">
      <i class="fa fa-globe"></i>
      {{siteUrl}}
    </span>
        <div class="row mt-2">
            <div class="col-xl-12">
                <KTPortlet v-bind:title="''">
                    <template v-slot:body>
                        <div class="row" v-if="loading">
                            <div class="col-md-12">
                                <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
                            </div>
                        </div>
                        <div class="row" v-else-if="!loading && customForms && customForms.length<1">
                          <div class="col-md-12 text-center">
                            <img src="@/assets/media/icons/no-data-found.png" class="no-data-img">
                            <h5 class="mt-10">Looks like you have not added any data here. Trying adding some data.</h5>
                          </div>
                        </div>
                        <div class="row" v-else>
                            <b-table
                                    hover
                                    responsive
                                    :items="customForms"
                                    id="my-table"
                                    :busy.sync="isBusy"
                                    :fields="fields"
                                    :current-page="currentPage"
                                    :per-page="perPage"
                            >
                                <template v-slot:table-busy>
                                    <div class="text-center text-info my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong>Loading Forms...</strong>
                                    </div>
                                </template>
                                <template v-slot:cell(is_active)="data">
                                    <i
                                            v-if="data.item.is_active"
                                            class="kt-nav__link-icon flaticon2-check-mark text-success"
                                    ></i>

                                    <i v-else class="kt-nav__link-icon flaticon2-cancel-music text-danger"></i>
                                </template>

                                <!-- A virtual composite column -->
                                <template v-slot:cell(action)="data">
                                    <div class="kt-widget__toolbar">
                                        <a
                                                href="#"
                                                class="btn btn-clean btn-sm btn-icon btn-icon-md"
                                                data-toggle="dropdown"
                                        >
                                            <i class="flaticon-more-1"></i>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right">
                                            <ul class="kt-nav">
                                                <li class="kt-nav__item">
                                                    <a href="#" @click="editCustomForm(data.item)" class="kt-nav__link">
                                                        <i class="kt-nav__link-icon flaticon-edit"></i>
                                                        <span class="kt-nav__link-text">Edit</span>
                                                    </a>
                                                </li>
                                                <li class="kt-nav__item">
                                                    <a href="#" class="kt-nav__link"
                                                       @click="deleteCustomForm(data.item.id)">
                                                        <i class="kt-nav__link-icon flaticon-delete kt-font-danger"></i>
                                                        <span class="kt-nav__link-text">Delete</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </template>
                            </b-table>
                            <div class="col-md-12 text-right">
                                <b-pagination
                                        v-model="currentPage"
                                        :total-rows="rows"
                                        :per-page="perPage"
                                        aria-controls="my-table"
                                        class="pull-right"
                                ></b-pagination>
                            </div>
                        </div>
                    </template>
                </KTPortlet>
            </div>
        </div>

        <v-app>
            <v-dialog scrollable v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
                <v-card>
                    <v-toolbar dark color="primary">
                        <v-btn icon dark @click="reset">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>Custom Form Builder</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn
                                    dark
                                    text
                                    @click.prevent="saveCustomForm"
                                    :loading="isBusy"
                            >{{ edit ? 'Update' : 'Save' }}
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-card-text>
                        <div class="form-block p-5">
                            <v-row>
                                <v-col cols="6" md="6">
                                    <v-text-field label="Form Name" v-model="customForm.title" required densed
                                                  outlined></v-text-field>
                                    <p
                                            class="text-danger font-weight-bold"
                                            v-if="$v.customForm.title.$error"
                                    >Form name is required</p>
                                </v-col>
                                <v-col cols="4" md="4">
                                    Shortcode for the form
                                    <p>[ custom_form id="{{customForm.shortCodeId}}" ]</p>

                                </v-col>
                                <v-col cols="2" md="2">
                                    <v-switch v-model="customForm.is_active" label="Active"></v-switch>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="3" md="3" sm="6" class="custom-form-tools">
                                    <v-card class="mx-auto custom-form-tools" tile>
                                        <v-list flat class="custom-form-tools">
                                            <v-subheader>Options</v-subheader>
                                            <v-list-item-group v-model="item" color="primary">
                                                <!-- <VueNestable v-model="items" key-prop="id" :maxDepth="1" cross-list group="cross">
                                                <div slot-scope="{ item }" :item="item">-->
                                                <v-list-item
                                                        v-for="item in items"
                                                        :key="item.text"
                                                        @click="addNewElement(item.value)"
                                                >
                                                    <v-list-item-icon>
                                                        <v-icon v-text="item.icon"></v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-text="item.text"></v-list-item-title>
                                                    </v-list-item-content>
                                                    <!-- <VueNestableHandle :item="item"> -->
                                                    <v-list-item-action>
                                                        <v-btn icon>
                                                            <v-icon color="green lighten-1">mdi-plus-box</v-icon>
                                                            Add
                                                        </v-btn>
                                                    </v-list-item-action>
                                                    <!-- </VueNestableHandle> -->
                                                </v-list-item>
                                                <!-- </div>
                                                </VueNestable>-->
                                            </v-list-item-group>
                                        </v-list>
                                    </v-card>
                                </v-col>
                                <v-col cols="6" md="6" sm="6">
                                    <draggable @change="changeList" v-model="elements" class="text-left" tag="tbody">
                                        <template v-for="(item,index) in elements" >
                                            <v-card outlined class="mt-2 custom-form-input-element" :key="index">
                                                <v-card-text>
                                                    <v-card-subtitle>
                                                        <VueNestableHandle :item="item">
                                                            <v-icon color="grey lighten-1">mdi-cursor-move</v-icon>
                                                        </VueNestableHandle>
                                                        {{ item.elementName }}
                                                        <span class="float-right" v-if="index !=elements.length-1 && item.elementName!='button'">
                                                          <v-icon
                                                                  @click="removeElement(index)"
                                                                  color="red lighten-1"
                                                          >mdi-minus-circle</v-icon>
                                                        </span>
                                                    </v-card-subtitle>

                                                    <v-row>
                                                        <v-col>
                                                            <v-text-field v-model="item.label" label="Label" outlined
                                                                          dense></v-text-field>
                                                        </v-col>
                                                        <v-col>
                                                            <v-text-field
                                                                    v-model="item.placeholder"
                                                                    label="placeholder"
                                                                    outlined
                                                                    dense
                                                            ></v-text-field>
                                                        </v-col>
                                                        <v-col>
                                                            <v-text-field v-model="item.name" label="Name" outlined
                                                                          dense></v-text-field>
                                                        </v-col>

                                                        <v-col>
                                                            <v-checkbox v-model="item.required"
                                                                        :label="item.required"></v-checkbox>
                                                        </v-col>
                                                    </v-row>

                                                    <v-row
                                                            v-if="item.type ==='select' || item.type ==='radio' || item.type ==='checkbox'"
                                                    >
                                                        <v-col>
                                                            <v-combobox
                                                                    v-model="item.options"
                                                                    hide-selected
                                                                    label="Add Options/Values"
                                                                    multiple
                                                                    outlined
                                                                    dense
                                                                    persistent-hint
                                                                    small-chips
                                                            >
                                                                <template v-slot:no-data>
                                                                    <v-list-item>
                                                                        <v-list-item-content>
                                                                            <v-list-item-title>
                                                                                Press
                                                                                <kbd>enter</kbd> to create a new one
                                                                            </v-list-item-title>
                                                                        </v-list-item-content>
                                                                    </v-list-item>
                                                                </template>
                                                            </v-combobox>
                                                        </v-col>
                                                        <!-- <v-col>
                                            <v-text-field outlined dense label="value"></v-text-field>
                                                        </v-col>-->
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </template>

                                    </draggable>
                                    <!-- <v-card class="mx-auto" tile>
                            <v-subheader>Options</v-subheader>
                                    </v-card>-->
<!--                                    <VueNestable-->
<!--                                            :maxDepth="1"-->
<!--                                            v-model="elements"-->
<!--                                            key-prop="sort_position"-->
<!--                                            class-prop="class"-->
<!--                                            group="cross"-->
<!--                                            @change="changeList"-->
<!--                                    >-->
<!--                                        <template slot-scope="{ item,index }">-->
<!--                                            &lt;!&ndash; Handler &ndash;&gt;-->

<!--                                            &lt;!&ndash; Content &ndash;&gt;-->
<!--                                            <v-card outlined class="mt-2 custom-form-input-element">-->
<!--                                                <v-card-text>-->
<!--                                                    <v-card-subtitle>-->
<!--                                                        <VueNestableHandle :item="item">-->
<!--                                                            <v-icon color="grey lighten-1">mdi-cursor-move</v-icon>-->
<!--                                                        </VueNestableHandle>-->
<!--                                                        {{ item.elementName }}-->
<!--                                                        <span class="float-right" v-if="index>=2">-->
<!--                                                          <v-icon-->
<!--                                                                  @click="removeElement(index)"-->
<!--                                                                  color="red lighten-1"-->
<!--                                                          >mdi-minus-circle</v-icon>-->
<!--                                                        </span>-->
<!--                                                    </v-card-subtitle>-->

<!--                                                    <v-row>-->
<!--                                                        <v-col>-->
<!--                                                            <v-text-field v-model="item.label" label="Label" outlined-->
<!--                                                                          dense></v-text-field>-->
<!--                                                        </v-col>-->
<!--                                                        <v-col>-->
<!--                                                            <v-text-field-->
<!--                                                                    v-model="item.placeholder"-->
<!--                                                                    label="placeholder"-->
<!--                                                                    outlined-->
<!--                                                                    dense-->
<!--                                                            ></v-text-field>-->
<!--                                                        </v-col>-->
<!--                                                        <v-col>-->
<!--                                                            <v-text-field v-model="item.name" label="Name" outlined-->
<!--                                                                          dense></v-text-field>-->
<!--                                                        </v-col>-->

<!--                                                        <v-col>-->
<!--                                                            <v-checkbox v-model="item.required"-->
<!--                                                                        :label="item.required"></v-checkbox>-->
<!--                                                        </v-col>-->
<!--                                                    </v-row>-->

<!--                                                    <v-row-->
<!--                                                            v-if="item.type ==='select' || item.type ==='radio' || item.type ==='checkbox'"-->
<!--                                                    >-->
<!--                                                        <v-col>-->
<!--                                                            <v-combobox-->
<!--                                                                    v-model="item.options"-->
<!--                                                                    hide-selected-->
<!--                                                                    label="Add Options/Values"-->
<!--                                                                    multiple-->
<!--                                                                    outlined-->
<!--                                                                    dense-->
<!--                                                                    persistent-hint-->
<!--                                                                    small-chips-->
<!--                                                            >-->
<!--                                                                <template v-slot:no-data>-->
<!--                                                                    <v-list-item>-->
<!--                                                                        <v-list-item-content>-->
<!--                                                                            <v-list-item-title>-->
<!--                                                                                Press-->
<!--                                                                                <kbd>enter</kbd> to create a new one-->
<!--                                                                            </v-list-item-title>-->
<!--                                                                        </v-list-item-content>-->
<!--                                                                    </v-list-item>-->
<!--                                                                </template>-->
<!--                                                            </v-combobox>-->
<!--                                                        </v-col>-->
<!--                                                        &lt;!&ndash; <v-col>-->
<!--                                            <v-text-field outlined dense label="value"></v-text-field>-->
<!--                                                        </v-col>&ndash;&gt;-->
<!--                                                    </v-row>-->
<!--                                                </v-card-text>-->
<!--                                            </v-card>-->
<!--                                        </template>-->
<!--                                    </VueNestable>-->
                                </v-col>
                                <v-col cols="3" md="3" sm="6" sticky>
                                    <v-card class="mx-auto custom-form-preview" tile>
                                        <v-card-title class="p-2"><h4 class="ml-2">Form Preview</h4></v-card-title>
                                        <draggable v-model="elements" class="text-left" tag="tbody">
                                            <template v-for="(item,preview_index) in elements">
                                                <v-card-text :key="preview_index">
                                                    <div v-if="item.type==='text-field'">
                                                        <span>{{item.label}}</span>
                                                        <v-text-field :placeholder="item.placeholder" outlined
                                                                      dense></v-text-field>
                                                    </div>
                                                    <div v-if="item.type==='email'">
                                                        <span>{{item.label}}</span>
                                                        <v-text-field
                                                                type="email"
                                                                :placeholder="item.placeholder"
                                                                outlined
                                                                dense
                                                        ></v-text-field>
                                                    </div>
                                                    <div v-if="item.type==='time'">
                                                        <span>{{item.label}}</span>
                                                        <v-text-field
                                                                type="time"
                                                                :placeholder="item.placeholder"
                                                                outlined
                                                                dense
                                                        ></v-text-field>
                                                    </div>
                                                    <div v-if="item.type==='number'">
                                                        <span>{{item.label}}</span>
                                                        <v-text-field
                                                                type="number"
                                                                :placeholder="item.placeholder"
                                                                outlined
                                                                dense
                                                        ></v-text-field>
                                                    </div>
                                                    <div v-if="item.type==='password'">
                                                        <span>{{item.label}}</span>
                                                        <v-text-field
                                                                type="password"
                                                                :placeholder="item.placeholder"
                                                                outlined
                                                                dense
                                                        ></v-text-field>
                                                    </div>
                                                    <div v-if="item.type==='file'">
                                                        <span>{{item.label}}</span>
                                                        <v-text-field
                                                                type="file"
                                                                :placeholder="item.placeholder"
                                                                outlined
                                                                dense
                                                        ></v-text-field>
                                                    </div>
                                                    <div v-if="item.type==='date'">
                                                        <span>{{item.label}}</span>
                                                        <v-text-field
                                                                type="date"
                                                                :placeholder="item.placeholder"
                                                                outlined
                                                                dense
                                                        ></v-text-field>
                                                    </div>
                                                    <div v-if="item.type==='button'">
                                                        <v-btn v-text="item.label" outlined dense></v-btn>
                                                    </div>
                                                    <div v-if="item.type==='text-area'">
                                                        <span>{{item.label}}</span>
                                                        <v-textarea :placeholder="item.placeholder" outlined
                                                                    dense></v-textarea>
                                                    </div>
                                                    <div v-if="item.type==='product'">
                                                        <span>{{item.label}}</span>
                                                        <v-select
                                                                :items="products"
                                                                item-text="title"
                                                                item-value="id"
                                                                :placeholder="item.placeholder"
                                                                outlined
                                                                dense
                                                        ></v-select>
                                                    </div>
                                                    <div v-if="item.type==='radio'">
                                                        <span>{{item.label}}</span>

                                                        <v-radio-group row>
                                                            <v-radio v-for="(item,i) in item.options" :key="i"
                                                                     :label="item"></v-radio>
                                                        </v-radio-group>
                                                    </div>
                                                    <div v-if="item.type==='checkbox'">
                                                        <span>{{item.label}}</span>
                                                        <v-row>
                                                            <v-col v-for="(item,i) in item.options" :key="i">
                                                                <v-checkbox :label="item"></v-checkbox>
                                                            </v-col>
                                                        </v-row>
                                                    </div>
                                                </v-card-text>
                                            </template>
                                        </draggable>

                                    </v-card>
                                </v-col>
                                <v-snackbar v-model="snackbar" :timeout="timeout">
                                    {{ text }}
                                    <v-btn color="blue" text @click="snackbar = false">Close</v-btn>
                                </v-snackbar>
                            </v-row>
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-app>
    </v-container>
</template>

<script>
    import draggable from 'vuedraggable'
    import {VueNestable, VueNestableHandle} from "vue-nestable";
    import WebsiteCustomFormService from "@/services/Websites/WebsiteCustomFormService";
    import KTPortlet from "@/views/partials/content/Portlet.vue";
    import {required} from "vuelidate/lib/validators";
    import ProductService from "@/services/store/ProductService";

    const Product = new ProductService();
    const WebsiteCustomForm = new WebsiteCustomFormService();

    export default {
        name: "vue-custom-form",
        display: "Table",
        components: {
            draggable,
            VueNestable,
            VueNestableHandle,
            KTPortlet
        },
        validations() {
            return {
                customForm: {
                    title: {required}
                }
            };
        },
        data: () => ({
            loading: true,
            item: 1,
            snackbar: false,
            products: [],
            customForm: {
                shortCodeId: Date.now(),
                title: "contact",
                form_data: null,
                link: null,
                is_active: 1
            },
            customForms: [],
            isBusy: false,
            dialog: false,
            edit: false,
            totalRows: 1,
            currentPage: 1,
            timeout: 2000,
            perPage: 10,
            pageOptions: [5, 10, 15],
            fields: [
                {key: "title", sortable: true},
                {key: "is_active", label: "Published ", sortable: false},
                {key: "action", sortable: false}
            ],
            items: [
                {
                    text: "Text Field",
                    value: "text-field",
                    icon: "mdi-open-in-new",
                    src: true,
                    id: 1
                },
                {
                    text: "Text Area",
                    value: "text-area",
                    icon: "mdi-tooltip-text",
                    src: true,
                    id: 2
                },
                {
                    text: "Select",
                    value: "select",
                    icon: "mdi-select-all",
                    src: true,
                    id: 3
                },
                {
                    text: "Radio Button",
                    value: "radio",
                    icon: "mdi-radiobox-marked",
                    src: true,
                    id: 4
                },
                {
                    text: "Checkbox",
                    value: "checkbox",
                    icon: "mdi-checkbox-multiple-marked",
                    src: true,
                    id: 5
                },
                {
                    text: "Email",
                    value: "email",
                    icon: "mdi-email-open",
                    src: true,
                    id: 6
                },
                {
                    text: "Number",
                    value: "number",
                    icon: "mdi-numeric-0-box-multiple",
                    src: true,
                    id: 7
                },
                {
                    text: "Password",
                    value: "password",
                    icon: "mdi-lock",
                    src: true,
                    id: 8
                },
                {
                    text: "Date",
                    value: "date",
                    icon: "mdi-calendar-multiple",
                    src: true,
                    id: 9
                },
                {
                    text: "Time",
                    value: "time",
                    icon: "mdi-calendar-multiple",
                    src: true,
                    id: 10
                },
                {
                    text: "Button",
                    value: "button",
                    icon: "mdi-link-variant",
                    src: true,
                    id: 11
                },
                {
                    text: "Products",
                    value: "Product",
                    icon: "mdi-link-variant",
                    src: true,
                    id: 12
                },
                {
                    text: "Files",
                    value: "File",
                    icon: "mdi-file",
                    src: true,
                    id: 13
                }
            ],
            text: "My timeout is set to 2000.",
            textField: {
                sort_position: null,
                value: null,
                label: "Label",
                placeholder: "PlaceHolder",
                name: "Name",
                required: "Required",
                type: "text-field",
                elementName: "Text Field",
                src: false
            },
            textArea: {
                sort_position: null,
                value: null,
                label: "Label",
                placeholder: "PlaceHolder",
                name: "Name",
                required: "Required",
                type: "text-area",
                elementName: "Text Area",
                src: false
            },
            select: {
                sort_position: null,
                value: null,
                label: "Label",
                placeholder: "PlaceHolder",
                name: "Name",
                required: "Required",
                type: "select",
                elementName: "Select",
                src: false,
                options: []
            },
            product: {
                sort_position: null,
                value: null,
                label: "Product",
                placeholder: "PlaceHolder",
                name: "Name",
                required: "Required",
                type: "product",
                elementName: "Product",
                src: false,
                options: []
            },
            file: {
                sort_position: null,
                value: null,
                label: "File",
                placeholder: "PlaceHolder",
                name: "Name",
                required: "Required",
                type: "file",
                elementName: "file",
                src: false,
                options: []
            },
            radio: {
                sort_position: null,
                value: null,
                label: "Label",
                placeholder: "PlaceHolder",
                name: "Name",
                required: "Required",
                type: "radio",
                elementName: "radio",
                src: false,
                options: []
            },
            checkbox: {
                sort_position: null,
                value: null,
                label: "Label",
                placeholder: "PlaceHolder",
                name: "Name",
                required: "Required",
                type: "checkbox",
                elementName: "checkbox",
                src: false,
                options: []
            },
            email: {
                sort_position: null,
                value: null,
                label: "Label",
                placeholder: "PlaceHolder",
                name: "Name",
                required: "Required",
                type: "email",
                elementName: "email",
                src: false
            },
            number: {
                sort_position: null,
                value: null,
                label: "Label",
                placeholder: "PlaceHolder",
                name: "Name",
                required: "Required",
                type: "number",
                elementName: "number",
                src: false
            },
            password: {
                sort_position: null,
                value: null,
                label: "Label",
                placeholder: "PlaceHolder",
                name: "Name",
                required: "Required",
                type: "password",
                elementName: "password",
                src: false
            },
            button: {
                sort_position: null,
                value: null,
                label: "Label",
                placeholder: "PlaceHolder",
                name: "Name",
                required: "Required",
                type: "button",
                elementName: "button",
                src: false
            },
            date: {
                sort_position: null,
                value: null,
                label: "Label",
                placeholder: "PlaceHolder",
                name: "Name",
                required: "Required",
                type: "date",
                elementName: "date",
                src: false
            },
            time: {
                sort_position: null,
                value: null,
                label: "Label",
                placeholder: "PlaceHolder",
                name: "Name",
                required: "Required",
                type: "time",
                elementName: "time",
                src: false
            },
            idCounter: 0,
            elements: [
                {
                    sort_position: 0,
                    label: "Label",
                    placeholder: "PlaceHolder",
                    name: "Name",
                    required: "Required",
                    type: "text-field",
                    elementName: "Text Field",
                    src: false
                },
                {
                    sort_position: 1,
                    value: null,
                    label: "Submit",
                    placeholder: "PlaceHolder",
                    name: "Name",
                    required: "Required",
                    type: "button",
                    elementName: "button",
                    src: false
                }
            ]
        }),
        mounted() {
            this.getCustomForm();
            this.getProducts();
        },
        methods: {
            showDialog() {
                this.dialog = true;
            },

            closeDialog() {
                this.dialog = false;
                this.$v.$reset();
            },
            addNewElement(type) {
                this.idCounter = Date.now();
                switch (type) {
                    case "text-field":
                        this.textField.sort_position = Date.now();
                        this.elements.unshift(this.textField);
                        break;
                    case "text-area":
                        this.textArea.sort_position = Date.now();
                        this.elements.unshift(this.textArea);
                        this.textArea.sort_position = null;
                        break;
                    case "select":
                        this.select.sort_position = Date.now();
                        this.elements.unshift(this.select);
                        this.select.sort_position = null;
                        break;
                    case "radio":
                        this.radio.sort_position = Date.now();
                        this.elements.unshift(this.radio);
                        this.radio.sort_position = null;
                        break;
                    case "checkbox":
                        this.checkbox.sort_position = Date.now();
                        this.elements.unshift(this.checkbox);
                        this.checkbox.sort_position = null;
                        break;
                    case "radio":
                        this.radio.sort_position = Date.now();
                        this.elements.unshift(this.radio);
                        this.radio.sort_position = null;
                        break;
                    case "email":
                        this.email.sort_position = Date.now();
                        this.elements.unshift(this.email);
                        this.email.sort_position = null;
                        break;
                    case "number":
                        this.number.sort_position = Date.now();
                        this.elements.unshift(this.number);
                        this.number.sort_position = null;
                        break;
                    case "password":
                        this.password.sort_position = Date.now();
                        this.elements.unshift(this.password);
                        this.password.sort_position = null;
                        break;
                    case "date":
                        this.date.sort_position = Date.now();
                        this.elements.unshift(this.date);
                        this.date.sort_position = null;
                        break;
                    case "time":
                        this.time.sort_position = Date.now();
                        this.elements.unshift(this.time);
                        this.time.sort_position = null;
                        break;
                    case "button":
                        this.button.sort_position = Date.now();
                        this.elements.unshift(this.button);
                        this.button.sort_position = null;
                        break;
                    case "Product":
                        this.product.sort_position = Date.now();
                        this.elements.unshift(this.product);
                        this.product.sort_position = null;
                        break;
                        case "File":
                        this.product.sort_position = Date.now();
                        this.elements.unshift(this.file);
                        this.product.sort_position = null;
                        break;
                }
            },
            removeElement(index) {
                this.elements.splice(index, 1);
            },
            changeList(value, option) {
                let current = this;
                if (value.src) {
                    ++this.idCounter;
                    // setTimeout(function() {
                    current.addNewElement(value.value);
                    current.removeElement(option.pathTo[0]);
                    current.items.push(value);
                    current.items.sort((a, b) => a.id - b.id);
                    // }, 200);
                }
            },
            deleteCustomForm(id) {
                this.$confirm(
                    {
                        message: `Are you sure?`,
                        button: {
                            no: 'No',
                            yes: 'Yes'
                        },
                        /**
                         * Callback Function
                         * @param {Boolean} confirm
                         */
                        callback: confirm => {
                            if (confirm) {
                                WebsiteCustomForm.delete(this.siteUrl, id).then(res => {
                                    this.$snotify.success("customform Deleted Sucessfully");
                                    this.getCustomForm();
                                });
                            }
                        }
                    }
                )

            },
            getProducts() {
                this.isBusy = true;
                Product.paginate(this.productFilter, this.currentPage)
                    .then(res => {
                        this.products = [];
                        this.products = res.data.data;
                    })
                    .catch(error => {
                        // console.log(error);
                    })
                    .finally(() => {
                        // this.loading = false;
                        // this.isBusy = false;
                    });
            },
            getCustomForm() {
                WebsiteCustomForm.paginate(this.siteUrl)
                    .then(res => {
                        this.customForms = res.data.data;
                    })
                    .catch(error => {
                        // console.log(error);
                    })
                    .finally(() => {
                        this.isBusy = false;
                        this.loading = false;
                    });
            },
            checkUniqueElement(key) {
                this.elements.forEach(element => {
                    if (key == element.sort_position) {
                        return true;
                    } else false;
                });
            },
            editCustomForm(item) {
                this.customForm = item;
                this.elements = JSON.parse(item.form_data);
                this.edit = true;
                this.showDialog();
            },
            saveCustomForm() {
                this.customForm.form_data = JSON.stringify(this.elements);
                if (this.edit) {
                    this.updateCustomForm();
                } else this.createCustomForm();
            },
            updateCustomForm() {
                this.isBusy = true;
                WebsiteCustomForm.update(
                    this.siteUrl,
                    this.customForm.id,
                    this.customForm
                )
                    .then(res => {
                        this.$snotify.success("Notice updated");
                        this.reset();
                    })
                    .catch(error => {
                        // console.log(error);
                    })
                    .finally(() => {
                        this.isBusy = false;
                    });
            },

            onCopy: function (e) {
                this.text = "You just copied URL ";
                this.snackbar = true;
            },
            onError: function (e) {
                this.text = "Failed to copy URL";
                this.snackbar = true;
            },
            reset() {
                this.dialog = false;
                this.isBusy = false;
                this.edit = false;
                (this.customForm = {
                    title: null,
                    form_data: null,
                    link: null,
                    is_active: 1
                }),
                    (this.elements = [
                        {
                            sort_position: 0,
                            label: "Label",
                            placeholder: "PlaceHolder",
                            name: "Name",
                            required: "Required",
                            type: "text-field",
                            elementName: "Text Field",
                            src: false
                        }
                    ]);
                this.$v.$reset();
                this.getCustomForm();
            },
            createCustomForm() {
                this.isBusy = true;
                WebsiteCustomForm.create(this.siteUrl, this.customForm)
                    .then(res => {
                        this.$snotify.success("Custom Form added");
                        this.reset();
                    })
                    .catch(error => {
                        // console.log(error);
                    })
                    .finally(() => {
                        this.isBusy = false;
                    });
            }
        },
        computed: {
            rows() {
                return this.customForms.length;
            },
            siteUrl() {
                return this.$route.params.domainname;
            },
            shortcode() {
                return "[ custom_form id= '" + this.customForm.shortCodeId + "' ]";
            }
        }
    };
</script>
<style>
    /*
    * Style for nestable
    */
    .nestable {
        position: relative;
    }

    .nestable-rtl {
        direction: rtl;
    }

    .nestable .nestable-list {
        margin: 0;
        padding: 0 0 0 40px;
        list-style-type: none;
    }

    .nestable-rtl .nestable-list {
        padding: 0 40px 0 0;
    }

    .nestable > .nestable-list {
        padding: 0;
    }

    .nestable-item,
    .nestable-item-copy {
        margin: 10px 0 0;
    }

    .nestable-item:first-child,
    .nestable-item-copy:first-child {
        margin-top: 0;
    }

    .nestable-item .nestable-list,
    .nestable-item-copy .nestable-list {
        margin-top: 10px;
    }

    .nestable-item {
        position: relative;
    }

    .nestable-item.is-dragging .nestable-list {
        pointer-events: none;
    }

    .nestable-item.is-dragging * {
        opacity: 0;
        filter: alpha(opacity=0);
    }

    .nestable-item.is-dragging:before {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(106, 127, 233, 0.274);
        border: 1px dashed rgb(73, 100, 241);
        -webkit-border-radius: 5px;
        border-radius: 5px;
    }

    .nestable-drag-layer {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        pointer-events: none;
    }

    .nestable-rtl .nestable-drag-layer {
        left: auto;
        right: 0;
    }

    .nestable-drag-layer > .nestable-list {
        position: absolute;
        top: 0;
        left: 0;
        padding: 0;
        background-color: rgba(106, 127, 233, 0.274);
    }

    .nestable-rtl .nestable-drag-layer > .nestable-list {
        padding: 0;
    }

    .nestable [draggable="true"] {
        cursor: move;
    }

    .nestable-handle {
        display: inline;
    }
</style>
